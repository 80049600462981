@use 'sass:map';
@use '../../variables' as page-blocks;
@use './variables' as global-block-hero;

.GlobalHeroBlock {

  &:first-child {

    @include media-breakpoint-down(sm) {
      margin-top: ((page-blocks.$gap-xs * -1) - global-block-hero.$offset-xs);
    }

    @include media-breakpoint-up(sm) {
      margin-top: ((page-blocks.$gap * -1) - global-block-hero.$offset);
    }
  }

  display: grid;
}

.GlobalHeroBlock__header__wrapper,
.GlobalHeroBlock__backgroundImage {
  grid-area: 1 / 1 / 2 / 2;
}

.GlobalHeroBlock__header__wrapper {
  position: relative;
  z-index: map.get(global-block-hero.$layers, 'header');

  min-height: 540px;
  padding: var(--bs-gutter-x, 0.75rem);

  @include media-breakpoint-down(sm) {
    padding-top: global-block-hero.$offset-xs;
  }

  @include media-breakpoint-up(sm) {
    padding-top: global-block-hero.$offset;
  }

  &.black {
    color: $color-black;
  }

  &.white {
    color: $color-white;
  }

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.GlobalHeroBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.75rem;
  }

  line-height: 1;
}

.GlobalHeroBlock__backgroundImage {
  position: relative;
  z-index: map.get(global-block-hero.$layers, 'backgroundImage');

  max-height: 640px;
}
