@use 'sass:math';
@use '../../variables' as slider-block;

.Slide {
  margin: 0; // override default <figure>

  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-content: center;
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    gap: 3rem;

    // force .Slide__caption to center-align
    &::before {
      content: '';
      flex: 0 0 auto;
    }
  }

  @include media-breakpoint-up(xl) {
    gap: 6rem;
  }
}

.Slide__img__placeholder,
.Slide__img__platform {
  order: 99;
}

.Slide__img__placeholder {

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.Slide__img__platform {
  flex: 0 0 auto;

  @include media-breakpoint-down(md) {
    width: (math.div(10, 12) * 100%);
    padding-bottom: 2.5rem;
  }

  @include media-breakpoint-only(md) {
    width: (math.div(7, 12) * 100%);
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 2.5rem;
  }

  @include media-breakpoint-up(lg) {
    width: (math.div(6, 12) * 100%);
  }
}

.Slide__img__stage {
  padding-top: 0.75rem;
  padding-right: 1.5rem;
  padding-bottom: 0.75rem;

  border-radius: 50%;
  background-color: $color-secondary;
  box-shadow: 0 0.25rem 1rem 0 rgba($color-black, 0.15);
}

.Slide__img__wrapper {
  position: relative; // for positioning .Slide__img

  // force 1/1 aspect ratio
  height: 0;
  padding-top: 100%;

  overflow: hidden;
  border-radius: 50%;
}

.Slide__img {
  object-fit: cover;
  object-position: center center;

  position: absolute; // relative to .Slide__img__wrapper
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.Slide__caption__wrapper {
  flex: 1 1 auto;

  @include media-breakpoint-up(md) {
    max-width: 65ch;
  }

  @include media-breakpoint-up(lg) {
    width: (math.div(7, 12) * 100%);
  }

  @include media-breakpoint-up(xl) {
    width: (math.div(6, 12) * 100%);
  }
}

.Slide__caption {
  padding-top: calc(3.5rem - math.div(slider-block.$dot-size, 2));
  padding-bottom: calc(3.5rem - math.div(slider-block.$dot-size, 2));

  @include media-breakpoint-down(lg) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.Slide__caption__header {
  margin-bottom: 0; // override default <h*>

  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  &::after {
    content: '';
    display: block;
    width: 4rem;
    height: 1px;

    background-color: $color-primary;
  }
}

.Slide__caption__body {

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.625rem;
  }

  line-height: 1.25;
}

.Slide__caption__meta {

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }

  &:not(:only-child) {
    padding-top: 1.5rem;
  }

  color: $color-primary;
  line-height: 1;
}
