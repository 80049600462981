.MapBlock {
    padding: 0;
    max-width: 100%;
    .HeaderRow {
        padding: 3rem;
        padding-bottom: 0;
        margin: 0 auto;
        max-width: $theme-max-width;
        text-align: center;
        h2 {
            font-weight: 500;
        }
    }
}