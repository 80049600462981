@use 'sass:map';
@use '../../components/PageBlocks/variables' as page-blocks;
@use './variables' as page;

.Page {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.Page__announcement__popup {
  z-index: map.get(page.$layers, 'announcementPopUp');
}

.Page__mainMenus {
  position: relative;
  z-index: map.get(page.$layers, 'mainMenus');
}

.Page__main {
  flex: 1 1 auto;
  position: relative;
  z-index: map.get(page.$layers, 'main');
  width: 100%;
}

.Page__mainMenus,
.Page__footer {
  flex: 0 0 auto;
  width: 100%;
}

.Page__footer {

  @include media-breakpoint-down(sm) {
    margin-top: page-blocks.$gap-xs;
  }

  @include media-breakpoint-up(sm) {
    margin-top: page-blocks.$gap;
  }
}
