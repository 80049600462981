@use 'sass:math';
.TextWithFactsBlock {
    // intentionally blank
}

.TextWithFactsBlock__header {
    margin: 0; // override default <h*>
    @include media-breakpoint-down(xxl) {
        font-size: 2.5rem;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 3rem;
    }
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    &::after {
        content: '';
        display: block;
        width: 4rem;
        height: 1px;
        background-color: $color-primary;
    }
}

.TextWithFactsBlock__body {
    padding-top: 1.5rem;
    max-width: 65ch;
    margin-right: auto;
    text-align: center;
    margin-left: auto;
    padding-right: calc(1.5rem - var(--bs-gutter-x, 0.75rem));
    padding-left: calc(1.5rem - var(--bs-gutter-x, 0.75rem));
    line-height: 2;
    @at-root p#{&} {
        margin-bottom: 0; // override default <p>
    }
    >p {
        &:last-child {
            margin-bottom: 0; // override default <p>
        }
    }
}

.TextWithFactsBlock__columns {
    @include media-breakpoint-up(lg) {
        width: (math.div(10, 12) * 100%);
        margin-right: auto;
        margin-left: auto;
    }
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.TextWithFactsBlock__column {
    text-align: center;
    &:only-child {
        width: 100%;
    }
    &:not(:only-child) {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        @include media-breakpoint-up(md) {
            flex: 0 1 auto;
            width: calc(#{math.div(4, 12) * 100%} - 1.5rem);
        }
    }
}

.TextWithFactsBlock__column__text {
    max-width: 65ch;
    margin-right: auto;
    margin-left: auto;
    line-height: 2;
    color: #657687;
    text-transform: uppercase;
}

.TextWithFactsBlock__column__header {
    color: $color-primary;
    font-size: 5rem;
    @include media-breakpoint-down(md) {
        font-size: 3.5rem;
    }
}