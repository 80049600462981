.HeaderParagraphBlock__wrapper {
  text-align: center;
}

.HeaderParagraphBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.HeaderParagraphBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  &::after {
    content: '';
    display: block;
    width: 4rem;
    height: 1px;

    background-color: $color-primary;
  }
}

.HeaderParagraphBlock__body {
  max-width: 65ch;
  margin-right: auto;
  margin-left: auto;

  padding-right: calc(1.5rem - var(--bs-gutter-x, 0.75rem));
  padding-left: calc(1.5rem - var(--bs-gutter-x, 0.75rem));

  line-height: 2;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}

.HeaderParagraphBlock__button {
  margin-top: 1.5rem;
}
