@use './variables' as footer;

.Footer__wrapper {
  display: flex;
  flex-direction: column;
  gap: footer.$gap;
}

.Footer {
  padding-right: footer.$gap;
  padding-bottom: footer.$gap;
  padding-left: footer.$gap;

  text-align: center;

  a {
    color: $color-primary;
  }
}

.Footer__logo__wrapper {
  // for pixel-perfect spacing
  margin-bottom: calc(footer.$gap - footer.$one-quarter-of-line-height);

  display: inline-block;
}

.Footer__logo {
  display: inline-block;
  width: auto;
  height: 3.5rem;

  color: $color-black;
  fill: currentColor;
}

.Footer__columns {
    padding-bottom: 2rem;
}

.Footer__accreditation {
    max-width: 110px;
    width: 100%;
    gap: 1em;
}

.Footer__pageLinks {
  // for pixel-perfect spacing
  margin-top: calc(footer.$gap - footer.$one-quarter-of-line-height);

  line-height: footer.$line-height;

  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  gap: 1em;
}

.Footer__pageLink {
  text-decoration: underline;
}
