@use '../Input/variables' as input;

.Wizard__wrapper {
  flex: 1 1 auto;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;
}

.Wizard {
  flex: 1 1 auto;
  width: 100%;
}

.Wizard__screens {
  overflow-x: hidden;
  overflow-y: hidden;

  height: 100%;

  margin-right: (input.$padding * -1);
  margin-left: (input.$padding * -1);

  display: flex;
  align-items: flex-start;
}

.Wizard__screen {
  flex: 0 0 auto;
  width: 100%;
  max-height: 100%;

  // background-color: red;

  @include media-breakpoint-up(sm) {
    min-height: 240px;
  }

  padding-right: input.$padding;
  padding-left: input.$padding;

  transition: transform 200ms ease-in-out;

  display: flex;
  flex-direction: column;

  &,
  .row {
    gap: (input.$padding * 2) 0;
  }
}

.Wizard__arrows {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.Wizard__arrows__button {
  // intentionally blank
}

.Wizard__pagination {
  // override default <ul>
  margin: 0;
  padding: 0;
  list-style: none;

  flex: 0 0 auto;
  order: -1;

  display: flex;
  align-items: center;
  justify-content: center;

  > li {
    display: inline-flex;
    align-items: center;

    &:not(:last-child) {

      &::after {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 2px;
        background-color: $color-gray-300;
      }
    }
  }
}

.Wizard__pagination__button {
  // override default <button>
  padding: 0;
  border: none;

  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;

  border-radius: 999px;
  border: 2px solid $color-primary;
  background-color: $color-white;

  &[disabled] {
    cursor: not-allowed;
    border-color: $color-gray-300;
  }

  &.isActive {
    border-color: $color-primary;
    background-color: $color-primary;
  }
}
