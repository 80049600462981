@use 'sass:math';

.FaqAccordionBlock {

    @include media-breakpoint-up(lg) {
        width: (math.div(10, 12) * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    display: flex;
    flex-direction: column;
    gap: 3rem;

}

.FaqAccordionBlock__accordion__item {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $color-secondary;

    .accordion-button {
        padding: 2rem;
        border: none;

        &:focus {
            border: initial;
            outline: 0;
            box-shadow: initial;
        }

        &:not(.collapsed) {
            color: initial;
            background-color: initial;
            box-shadow: initial;
        }
    }
}

.FaqAccordionBlock__accordion__header {
    h3 {
        margin: 0;
        font-size: 1.125rem;
    }
}

.FaqAccordionBlock__accordion__body {
    font-size: 0.875rem;
    font-weight: 500;
    @at-root p#{&} {
        margin-bottom: 0; // override default <p>
      }
    
      > p {
    
        &:last-child {
          margin-bottom: 0; // override default <p>
        }
      }
}
