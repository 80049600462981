.CompanyInfo {
  line-height: 2;
  text-align: center;
}

.CompanyInfo__address {

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}

.CompanyInfo__email {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.CompanyInfo__icons {
  margin-top: 1em;

  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  gap: 1em;
}

.CompanyInfo__icon__wrapper {
  display: block;
  width: 2em;
  height: 2em;
}

.CompanyInfo__icon {
  display: block;

  fill: currentColor;
}
