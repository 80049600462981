@use '../Input/variables' as input;

@import './components/RadioOption/RadioOption';

.RadioGroup {

  &:not(.hasLabel) {
    margin-top: (input.$padding * -1);
  }

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  gap: input.$padding;
}

.RadioGroup__options {
  margin-bottom: (input.$padding * -1);

  &.hasOtherOption {
    margin-bottom: 0;
  }

  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 0 (input.$padding * 3);

  &.isStacked {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
