@use 'sass:map';
@use 'sass:math';
@use '../Input/variables' as input;
@use './variables' as select;

.Select {
  // intentionally blank
}

.Select__fauxIcon__wrapper {
  position: relative; // ???

  display: flex;
  align-items: stretch;
}

.Select__select {
  // override default <select>
  appearance: none;
  background: none;
  border: none;
  outline: none;

  order: -1;

  position: relative;
  z-index: map.get(select.$layers, 'select');

  display: block;
  width: 100%;
  height: input.$height;
  padding: input.$padding;

  color: $color-black;
  line-height: 1;
}

.Select__fauxIcon {
  $this: &;

  // override default <button>
  padding: 0;
  border: none;
  background: none;
  outline: none;

  position: relative;
  z-index: map.get(select.$layers, 'fauxIcon');

  flex: 0 0 auto;

  padding: input.$padding;

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    display: block;
    width: 0.5em;
    height: 0.5em;

    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: currentColor;

    transform:
      rotate(45deg)
      translate3d(-0.0625em, -0.0625em, 0);
  }

  @at-root {

    .Select.error #{$this} {
      color: red;
    }

    .Select__select:focus ~ #{$this} {

      &::after {
        color: $color-primary;
        transform:
          rotate(225deg)
          translate3d(-0.0625em, -0.0625em, 0);
      }
    }
  }
}

.Select__fauxBg {
  $this: &;

  position: absolute;
  z-index: map.get(select.$layers, 'fauxIconWrapperBg');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 3px;
  border: 1px solid $color-gray-200;
  background-color: $color-gray-200;

  pointer-events: none;

  @at-root {

    .Select.error #{$this} {
      border-color: red;
      background-color: $color-white;
    }

    .Select__select:focus ~ #{$this} {
      border-color: $color-primary;
      background-color: $color-white;
    }
  }
}
