.Header {

  &.isLongHeader {

    @include media-breakpoint-down(xxl) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 2.5rem;
    }
  }
}
