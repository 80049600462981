.ErrorMessage {
  color: red;
  font-size: 80%;
  font-weight: bold;
  line-height: 1;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}
