@use 'sass:math';

.PaymentFormBlock__wrapper {
  // intentionally blank
}

.PaymentFormBlock {

  @include media-breakpoint-up(lg) {
    width: (math.div(10, 12) * 100%);
    margin-right: auto;
    margin-left: auto;
  }

  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.PaymentFormBlock__header__wrapper {

  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;
}

.PaymentFormBlock__header {
  margin: 0; // override default <h*>
}

.PaymentFormBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  &::after {
    content: '';
    display: block;
    width: 4rem;
    height: 1px;

    background-color: $color-primary;
  }
}

.PaymentFormBlock__body {
  max-width: 65ch;
  margin-right: auto;
  margin-left: auto;

  padding-right: calc(1.5rem - var(--bs-gutter-x, 0.75rem));
  padding-left: calc(1.5rem - var(--bs-gutter-x, 0.75rem));

  line-height: 2;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}

.PaymentFormBlock__form {

  .InputWrapper {
    padding-bottom: 2rem;
  }
}
.PaymentFormBlock__button {
  width: 100%;
}
.PaymentFormBlock__small__text {
  font-size: 0.66rem;
}

.PolicyForm__message {
  padding-bottom: 1rem;
  h3 {
    font-size: 1.5rem;
  }
}


