@use 'sass:map';
@use 'sass:math';
@use './variables' as main-menus;
@use './components/Logo/variables' as logo;
@use './components/TopLinks/variables' as top-links;

@import './components/Logo/Logo';
@import './components/MainLinks/MainLinks';
@import './components/TopLinks/TopLinks';

.MainMenus {
  border-bottom: 1px solid $color-secondary;
  background-color: rgba($color-white, 0.4);
  box-shadow: 0 0.25rem 1rem 0 rgba($color-black, 0.15);

  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    align-items: flex-start;

    padding-right: calc(1.5rem - var(--bs-gutter-x, 0.75rem));
    padding-left: calc(1.5rem - var(--bs-gutter-x, 0.75rem));
  }

  @include media-breakpoint-up(sm) {
    align-items: center;
    text-align: center;
  }

  &.areMainLinksVisible {
    color: $color-secondary;
    background-color: $color-black;
  }
}

.MainMenus__logo {
  position: relative;
  z-index: map.get(main-menus.$layers, 'logo');
}

.MainMenus__topLinks {
  order: -1;

  position: relative;
  z-index: map.get(main-menus.$layers, 'topLinks');

  width: 100%;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.MainMenus__mainLinks {
  z-index: map.get(main-menus.$layers, 'mainLinks');
}
